import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as aok_45plusKN7oWiHkhwMeta } from "/opt/build/repo/pages/aok-plus.vue?macro=true";
import { default as aok_45rps35fvQ8EWneMeta } from "/opt/build/repo/pages/aok-rps.vue?macro=true";
import { default as app_45erklaerung_45aktualisierungCMmDiD2WdeMeta } from "/opt/build/repo/pages/app-erklaerung-aktualisierung.vue?macro=true";
import { default as _91slug_932BH3UhQjmqMeta } from "/opt/build/repo/pages/blog/[slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91page_93FkslPclYq1Meta } from "/opt/build/repo/pages/blog/page/[page].vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as cta8evvKdYIuFMeta } from "/opt/build/repo/pages/cta.vue?macro=true";
import { default as dataProtection3XyJPiJU5wMeta } from "/opt/build/repo/pages/dataProtection.vue?macro=true";
import { default as downloadxp7BeEEFTyMeta } from "/opt/build/repo/pages/download.vue?macro=true";
import { default as imprintqFImHeYkTxMeta } from "/opt/build/repo/pages/imprint.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as mobil_45krankenkasseI5ZXKMRH23Meta } from "/opt/build/repo/pages/mobil-krankenkasse.vue?macro=true";
import { default as pat10nxe7SZb9Meta } from "/opt/build/repo/pages/pat.vue?macro=true";
import { default as press1stEwxXIGNMeta } from "/opt/build/repo/pages/press.vue?macro=true";
import { default as quiz_45result0m3ufA5SQdMeta } from "/opt/build/repo/pages/quiz-result.vue?macro=true";
import { default as quizGW4WUV9ZbpMeta } from "/opt/build/repo/pages/quiz.vue?macro=true";
import { default as studie_45pkg5F6aq1dgz2Meta } from "/opt/build/repo/pages/studie-pkg.vue?macro=true";
import { default as ergebnis_45negativfpJrXfH3OpMeta } from "/opt/build/repo/pages/studie/ergebnis-negativ.vue?macro=true";
import { default as ergebnis_45positiv77zogjnrnfMeta } from "/opt/build/repo/pages/studie/ergebnis-positiv.vue?macro=true";
import { default as indexoCf58V7leRMeta } from "/opt/build/repo/pages/studie/index.vue?macro=true";
import { default as landingaRN8WAooFXMeta } from "/opt/build/repo/pages/studie/landing.vue?macro=true";
import { default as supportersrR4rS3ZBLGMeta } from "/opt/build/repo/pages/supporters.vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___en",
    path: aboutlv6i1FL0CfMeta?.path ?? "/en/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about___de",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aok_45plusKN7oWiHkhwMeta?.name ?? "aok-plus___en",
    path: aok_45plusKN7oWiHkhwMeta?.path ?? "/en/aok-plus",
    meta: aok_45plusKN7oWiHkhwMeta || {},
    alias: aok_45plusKN7oWiHkhwMeta?.alias || [],
    redirect: aok_45plusKN7oWiHkhwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/aok-plus.vue").then(m => m.default || m)
  },
  {
    name: aok_45plusKN7oWiHkhwMeta?.name ?? "aok-plus___de",
    path: aok_45plusKN7oWiHkhwMeta?.path ?? "/aok-plus",
    meta: aok_45plusKN7oWiHkhwMeta || {},
    alias: aok_45plusKN7oWiHkhwMeta?.alias || [],
    redirect: aok_45plusKN7oWiHkhwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/aok-plus.vue").then(m => m.default || m)
  },
  {
    name: aok_45rps35fvQ8EWneMeta?.name ?? "aok-rps___en",
    path: aok_45rps35fvQ8EWneMeta?.path ?? "/en/aok-rps",
    meta: aok_45rps35fvQ8EWneMeta || {},
    alias: aok_45rps35fvQ8EWneMeta?.alias || [],
    redirect: aok_45rps35fvQ8EWneMeta?.redirect,
    component: () => import("/opt/build/repo/pages/aok-rps.vue").then(m => m.default || m)
  },
  {
    name: aok_45rps35fvQ8EWneMeta?.name ?? "aok-rps___de",
    path: aok_45rps35fvQ8EWneMeta?.path ?? "/aok-rps",
    meta: aok_45rps35fvQ8EWneMeta || {},
    alias: aok_45rps35fvQ8EWneMeta?.alias || [],
    redirect: aok_45rps35fvQ8EWneMeta?.redirect,
    component: () => import("/opt/build/repo/pages/aok-rps.vue").then(m => m.default || m)
  },
  {
    name: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.name ?? "app-erklaerung-aktualisierung___en",
    path: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.path ?? "/en/app-erklaerung-aktualisierung",
    meta: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta || {},
    alias: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.alias || [],
    redirect: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app-erklaerung-aktualisierung.vue").then(m => m.default || m)
  },
  {
    name: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.name ?? "app-erklaerung-aktualisierung___de",
    path: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.path ?? "/app-erklaerung-aktualisierung",
    meta: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta || {},
    alias: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.alias || [],
    redirect: app_45erklaerung_45aktualisierungCMmDiD2WdeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app-erklaerung-aktualisierung.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___en",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932BH3UhQjmqMeta?.name ?? "blog-slug___de",
    path: _91slug_932BH3UhQjmqMeta?.path ?? "/blog/:slug()",
    meta: _91slug_932BH3UhQjmqMeta || {},
    alias: _91slug_932BH3UhQjmqMeta?.alias || [],
    redirect: _91slug_932BH3UhQjmqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___en",
    path: indexmFsYN4t0XfMeta?.path ?? "/en/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog___de",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93FkslPclYq1Meta?.name ?? "blog-page-page___en",
    path: _91page_93FkslPclYq1Meta?.path ?? "/en/blog/page/:page()",
    meta: _91page_93FkslPclYq1Meta || {},
    alias: _91page_93FkslPclYq1Meta?.alias || [],
    redirect: _91page_93FkslPclYq1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91page_93FkslPclYq1Meta?.name ?? "blog-page-page___de",
    path: _91page_93FkslPclYq1Meta?.path ?? "/blog/page/:page()",
    meta: _91page_93FkslPclYq1Meta || {},
    alias: _91page_93FkslPclYq1Meta?.alias || [],
    redirect: _91page_93FkslPclYq1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___en",
    path: contactNfojXvLlAkMeta?.path ?? "/en/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___de",
    path: contactNfojXvLlAkMeta?.path ?? "/kontakt",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cta8evvKdYIuFMeta?.name ?? "cta___en",
    path: cta8evvKdYIuFMeta?.path ?? "/en/cta",
    meta: cta8evvKdYIuFMeta || {},
    alias: cta8evvKdYIuFMeta?.alias || [],
    redirect: cta8evvKdYIuFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cta.vue").then(m => m.default || m)
  },
  {
    name: cta8evvKdYIuFMeta?.name ?? "cta___de",
    path: cta8evvKdYIuFMeta?.path ?? "/cta",
    meta: cta8evvKdYIuFMeta || {},
    alias: cta8evvKdYIuFMeta?.alias || [],
    redirect: cta8evvKdYIuFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cta.vue").then(m => m.default || m)
  },
  {
    name: dataProtection3XyJPiJU5wMeta?.name ?? "dataProtection___en",
    path: dataProtection3XyJPiJU5wMeta?.path ?? "/en/data-protection",
    meta: dataProtection3XyJPiJU5wMeta || {},
    alias: dataProtection3XyJPiJU5wMeta?.alias || [],
    redirect: dataProtection3XyJPiJU5wMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dataProtection.vue").then(m => m.default || m)
  },
  {
    name: dataProtection3XyJPiJU5wMeta?.name ?? "dataProtection___de",
    path: dataProtection3XyJPiJU5wMeta?.path ?? "/datenschutz",
    meta: dataProtection3XyJPiJU5wMeta || {},
    alias: dataProtection3XyJPiJU5wMeta?.alias || [],
    redirect: dataProtection3XyJPiJU5wMeta?.redirect,
    component: () => import("/opt/build/repo/pages/dataProtection.vue").then(m => m.default || m)
  },
  {
    name: downloadxp7BeEEFTyMeta?.name ?? "download___en",
    path: downloadxp7BeEEFTyMeta?.path ?? "/en/download",
    meta: downloadxp7BeEEFTyMeta || {},
    alias: downloadxp7BeEEFTyMeta?.alias || [],
    redirect: downloadxp7BeEEFTyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/download.vue").then(m => m.default || m)
  },
  {
    name: downloadxp7BeEEFTyMeta?.name ?? "download___de",
    path: downloadxp7BeEEFTyMeta?.path ?? "/herunterladen",
    meta: downloadxp7BeEEFTyMeta || {},
    alias: downloadxp7BeEEFTyMeta?.alias || [],
    redirect: downloadxp7BeEEFTyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/download.vue").then(m => m.default || m)
  },
  {
    name: imprintqFImHeYkTxMeta?.name ?? "imprint___en",
    path: imprintqFImHeYkTxMeta?.path ?? "/en/imprint",
    meta: imprintqFImHeYkTxMeta || {},
    alias: imprintqFImHeYkTxMeta?.alias || [],
    redirect: imprintqFImHeYkTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintqFImHeYkTxMeta?.name ?? "imprint___de",
    path: imprintqFImHeYkTxMeta?.path ?? "/impressum",
    meta: imprintqFImHeYkTxMeta || {},
    alias: imprintqFImHeYkTxMeta?.alias || [],
    redirect: imprintqFImHeYkTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mobil_45krankenkasseI5ZXKMRH23Meta?.name ?? "mobil-krankenkasse___en",
    path: mobil_45krankenkasseI5ZXKMRH23Meta?.path ?? "/en/mobil-krankenkasse",
    meta: mobil_45krankenkasseI5ZXKMRH23Meta || {},
    alias: mobil_45krankenkasseI5ZXKMRH23Meta?.alias || [],
    redirect: mobil_45krankenkasseI5ZXKMRH23Meta?.redirect,
    component: () => import("/opt/build/repo/pages/mobil-krankenkasse.vue").then(m => m.default || m)
  },
  {
    name: mobil_45krankenkasseI5ZXKMRH23Meta?.name ?? "mobil-krankenkasse___de",
    path: mobil_45krankenkasseI5ZXKMRH23Meta?.path ?? "/mobil-krankenkasse",
    meta: mobil_45krankenkasseI5ZXKMRH23Meta || {},
    alias: mobil_45krankenkasseI5ZXKMRH23Meta?.alias || [],
    redirect: mobil_45krankenkasseI5ZXKMRH23Meta?.redirect,
    component: () => import("/opt/build/repo/pages/mobil-krankenkasse.vue").then(m => m.default || m)
  },
  {
    name: pat10nxe7SZb9Meta?.name ?? "pat___en",
    path: pat10nxe7SZb9Meta?.path ?? "/en/pat",
    meta: pat10nxe7SZb9Meta || {},
    alias: pat10nxe7SZb9Meta?.alias || [],
    redirect: pat10nxe7SZb9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/pat.vue").then(m => m.default || m)
  },
  {
    name: pat10nxe7SZb9Meta?.name ?? "pat___de",
    path: pat10nxe7SZb9Meta?.path ?? "/pat",
    meta: pat10nxe7SZb9Meta || {},
    alias: pat10nxe7SZb9Meta?.alias || [],
    redirect: pat10nxe7SZb9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/pat.vue").then(m => m.default || m)
  },
  {
    name: press1stEwxXIGNMeta?.name ?? "press___en",
    path: press1stEwxXIGNMeta?.path ?? "/en/press",
    meta: press1stEwxXIGNMeta || {},
    alias: press1stEwxXIGNMeta?.alias || [],
    redirect: press1stEwxXIGNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/press.vue").then(m => m.default || m)
  },
  {
    name: press1stEwxXIGNMeta?.name ?? "press___de",
    path: press1stEwxXIGNMeta?.path ?? "/presse",
    meta: press1stEwxXIGNMeta || {},
    alias: press1stEwxXIGNMeta?.alias || [],
    redirect: press1stEwxXIGNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/press.vue").then(m => m.default || m)
  },
  {
    name: quiz_45result0m3ufA5SQdMeta?.name ?? "quiz-result___en",
    path: quiz_45result0m3ufA5SQdMeta?.path ?? "/en/quiz-result",
    meta: quiz_45result0m3ufA5SQdMeta || {},
    alias: quiz_45result0m3ufA5SQdMeta?.alias || [],
    redirect: quiz_45result0m3ufA5SQdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quiz-result.vue").then(m => m.default || m)
  },
  {
    name: quiz_45result0m3ufA5SQdMeta?.name ?? "quiz-result___de",
    path: quiz_45result0m3ufA5SQdMeta?.path ?? "/quiz-result",
    meta: quiz_45result0m3ufA5SQdMeta || {},
    alias: quiz_45result0m3ufA5SQdMeta?.alias || [],
    redirect: quiz_45result0m3ufA5SQdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quiz-result.vue").then(m => m.default || m)
  },
  {
    name: quizGW4WUV9ZbpMeta?.name ?? "quiz___en",
    path: quizGW4WUV9ZbpMeta?.path ?? "/en/quiz",
    meta: quizGW4WUV9ZbpMeta || {},
    alias: quizGW4WUV9ZbpMeta?.alias || [],
    redirect: quizGW4WUV9ZbpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quiz.vue").then(m => m.default || m)
  },
  {
    name: quizGW4WUV9ZbpMeta?.name ?? "quiz___de",
    path: quizGW4WUV9ZbpMeta?.path ?? "/quiz",
    meta: quizGW4WUV9ZbpMeta || {},
    alias: quizGW4WUV9ZbpMeta?.alias || [],
    redirect: quizGW4WUV9ZbpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quiz.vue").then(m => m.default || m)
  },
  {
    name: studie_45pkg5F6aq1dgz2Meta?.name ?? "studie-pkg___en",
    path: studie_45pkg5F6aq1dgz2Meta?.path ?? "/en/studie-pkg",
    meta: studie_45pkg5F6aq1dgz2Meta || {},
    alias: studie_45pkg5F6aq1dgz2Meta?.alias || [],
    redirect: studie_45pkg5F6aq1dgz2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/studie-pkg.vue").then(m => m.default || m)
  },
  {
    name: studie_45pkg5F6aq1dgz2Meta?.name ?? "studie-pkg___de",
    path: studie_45pkg5F6aq1dgz2Meta?.path ?? "/studie-pkg",
    meta: studie_45pkg5F6aq1dgz2Meta || {},
    alias: studie_45pkg5F6aq1dgz2Meta?.alias || [],
    redirect: studie_45pkg5F6aq1dgz2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/studie-pkg.vue").then(m => m.default || m)
  },
  {
    name: ergebnis_45negativfpJrXfH3OpMeta?.name ?? "studie-ergebnis-negativ___en",
    path: ergebnis_45negativfpJrXfH3OpMeta?.path ?? "/en/studie/ergebnis-negativ",
    meta: ergebnis_45negativfpJrXfH3OpMeta || {},
    alias: ergebnis_45negativfpJrXfH3OpMeta?.alias || [],
    redirect: ergebnis_45negativfpJrXfH3OpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/ergebnis-negativ.vue").then(m => m.default || m)
  },
  {
    name: ergebnis_45negativfpJrXfH3OpMeta?.name ?? "studie-ergebnis-negativ___de",
    path: ergebnis_45negativfpJrXfH3OpMeta?.path ?? "/studie/ergebnis-negativ",
    meta: ergebnis_45negativfpJrXfH3OpMeta || {},
    alias: ergebnis_45negativfpJrXfH3OpMeta?.alias || [],
    redirect: ergebnis_45negativfpJrXfH3OpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/ergebnis-negativ.vue").then(m => m.default || m)
  },
  {
    name: ergebnis_45positiv77zogjnrnfMeta?.name ?? "studie-ergebnis-positiv___en",
    path: ergebnis_45positiv77zogjnrnfMeta?.path ?? "/en/studie/ergebnis-positiv",
    meta: ergebnis_45positiv77zogjnrnfMeta || {},
    alias: ergebnis_45positiv77zogjnrnfMeta?.alias || [],
    redirect: ergebnis_45positiv77zogjnrnfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/ergebnis-positiv.vue").then(m => m.default || m)
  },
  {
    name: ergebnis_45positiv77zogjnrnfMeta?.name ?? "studie-ergebnis-positiv___de",
    path: ergebnis_45positiv77zogjnrnfMeta?.path ?? "/studie/ergebnis-positiv",
    meta: ergebnis_45positiv77zogjnrnfMeta || {},
    alias: ergebnis_45positiv77zogjnrnfMeta?.alias || [],
    redirect: ergebnis_45positiv77zogjnrnfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/ergebnis-positiv.vue").then(m => m.default || m)
  },
  {
    name: indexoCf58V7leRMeta?.name ?? "studie___en",
    path: indexoCf58V7leRMeta?.path ?? "/en/studie",
    meta: indexoCf58V7leRMeta || {},
    alias: indexoCf58V7leRMeta?.alias || [],
    redirect: indexoCf58V7leRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/index.vue").then(m => m.default || m)
  },
  {
    name: indexoCf58V7leRMeta?.name ?? "studie___de",
    path: indexoCf58V7leRMeta?.path ?? "/studie",
    meta: indexoCf58V7leRMeta || {},
    alias: indexoCf58V7leRMeta?.alias || [],
    redirect: indexoCf58V7leRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/index.vue").then(m => m.default || m)
  },
  {
    name: landingaRN8WAooFXMeta?.name ?? "studie-landing___en",
    path: landingaRN8WAooFXMeta?.path ?? "/en/studie/landing",
    meta: landingaRN8WAooFXMeta || {},
    alias: landingaRN8WAooFXMeta?.alias || [],
    redirect: landingaRN8WAooFXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/landing.vue").then(m => m.default || m)
  },
  {
    name: landingaRN8WAooFXMeta?.name ?? "studie-landing___de",
    path: landingaRN8WAooFXMeta?.path ?? "/studie/landing",
    meta: landingaRN8WAooFXMeta || {},
    alias: landingaRN8WAooFXMeta?.alias || [],
    redirect: landingaRN8WAooFXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/studie/landing.vue").then(m => m.default || m)
  },
  {
    name: supportersrR4rS3ZBLGMeta?.name ?? "supporters___en",
    path: supportersrR4rS3ZBLGMeta?.path ?? "/en/supporters",
    meta: supportersrR4rS3ZBLGMeta || {},
    alias: supportersrR4rS3ZBLGMeta?.alias || [],
    redirect: supportersrR4rS3ZBLGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/supporters.vue").then(m => m.default || m)
  },
  {
    name: supportersrR4rS3ZBLGMeta?.name ?? "supporters___de",
    path: supportersrR4rS3ZBLGMeta?.path ?? "/foerderung",
    meta: supportersrR4rS3ZBLGMeta || {},
    alias: supportersrR4rS3ZBLGMeta?.alias || [],
    redirect: supportersrR4rS3ZBLGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/supporters.vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/sitemap.xml",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: () => import("/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]