<script setup>
const route = useRoute()
const currentRoute = route.fullPath
console.log(currentRoute)
</script>

<template>
  <div
    id="not-found-page"
    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center ml-5 mr-5"
  >
    <h2 v-if="error.statusCode === 404">
      {{ $t("general.error404") }}
    </h2>
    <h2 v-else>
      {{ $t("general.errorMisc") }}
    </h2>
    <p>
      {{ $t("general.callToAction") }}
      <nuxt-link id="contact-form" :to="localePath('contact') + '?redirect=' + currentRoute">
        {{ $t("general.linkToContact") }}
      </nuxt-link>
    </p>
    <nuxt-link :to="localePath('/')">
      <h4>{{ $t("general.linkToIndex") }}</h4>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  layout: "ErrorLayout",
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
#contact-form {
  font-weight: 600;
}
#not-found-page {
  margin-top: 20%;
}

p {
  margin-bottom: 40px;
}

h5 {
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 48vh;
}
</style>
